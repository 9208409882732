import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Tooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import styles from './styles'

const AuctionDetails = ({ auctionDetails }) => {
  const classes = styles()

  const renderRiskToolTipText = () => (
    <div style={{ fontSize: 16 }}>
      <p>Índice de Risco derivado da condição Geral do veículo publicado pelo Leiloeiro:</p>
      <p>1 - Sem Risco</p>
      <p>2 - Baixo Risco</p>
      <p>3 - Médio Risco</p>
      <p>4 - Alto / Grande Risco</p>
      <p>5 - Risco Inaceitável / Fraude / Sucata</p>
    </div>
  )

  const renderImages = () => {
    const images = _.get(auctionDetails, 'images')
    if (_.isEmpty(images)) {
      return <p>Imagens de leilão não disponíveis para o veiculo consultado</p>
    }

    return _.map(images, (image, i) => (
      <div key={i.toString()} className={classes.containerImage}>
        <img
          src={image.image_url_assigned}
          alt="imagem"
          className={classes.image}
        />
      </div>
    ))
  }

  const renderAuctionsDetails = () => {
    if (_.isEmpty(auctionDetails)) return null

    return (
      <React.Fragment>
        <div className={classes.containerLine}>
          <div className={classes.containerContentText}>
            <span className={classes.description}>Lote:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'lot')}</span>
          </div>

          <span className={classes.vl} />

          <div className={classes.containerContentText}>
            <span className={classes.description}>Leiloeiro:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'auctioneer')}</span>
          </div>
        </div>

        <hr className={classes.hl} />

        <div className={classes.containerLine}>
          <div className={classes.containerContentText}>
            <span className={classes.description}>Nº Leilão:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'auction_id')}</span>
          </div>

          <span className={classes.vl} />

          <div className={classes.containerContentText}>
            <span className={classes.description}>Comitente:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'principal')}</span>
          </div>
        </div>

        <hr className={classes.hl} />

        <div className={classes.containerLine}>
          <div className={classNames(classes.containerContentText, classes.containerContentTextLarge)}>
            <span className={classes.description}>Patio:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'yard')}</span>
          </div>
        </div>

        <hr className={classes.hl} />

        <div className={classes.containerLine}>
          <div className={classNames(classes.containerContentText, classes.containerContentTextLarge)}>
            <span className={classes.description}>Situação do Chassi:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'TODO: DEFINIR CAMPO')}</span>
          </div>
        </div>

        <hr className={classes.hl} />

        <div className={classes.containerLine}>
          <div className={classNames(classes.containerContentText, classes.containerContentTextLarge)}>
            <span className={classes.description}>Condição geral do veiculo:</span>
            <span className={classes.value}>{_.get(auctionDetails, 'vehicle_condition')}</span>
          </div>
        </div>

        <hr className={classes.hl} />

        <div className={classes.containerLine}>
          <div className={classNames(classes.containerContentText, classes.containerContentTextLarge)}>
            <span className={classNames(classes.description, classes.containerFlex)}>
              <span>Indíce de risco</span>
              {' '}
              <Tooltip placement="top" title={renderRiskToolTipText()} interactive={true}>
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
              <span>:</span>
            </span>
            <span className={classes.value}>
              {`${_.get(auctionDetails, 'scrap')} - ${_.get(auctionDetails, 'scrap_description')}`}
            </span>
          </div>
        </div>

        <hr className={classes.hl} />

        <div className={classes.containerImages}>
          {renderImages()}
        </div>

        <hr className={classes.hl} />

        <p>
          Os serviços consistem em coletar e reunir uma parte das informações publicadas em mídia impressa e eletrônica pelos leiloeiros oficiais. Os dados e fotos exibidos foram publicamente fornecidos pelos respectivos leiloeiros, conforme Art.23 e Art.38 do Decreto Federal 21.981 de 19/out/1932. Os termos empregados nestas publicações , bem como eventuais divergências nos dados fornecidos pelos leiloeiros oficiais são de responsabilidade de seus autores.
        </p>
      </React.Fragment>
    )
  }

  return renderAuctionsDetails()
}

export default AuctionDetails
