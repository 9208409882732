import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import { Reducers } from './rootReducers'
import rootSaga from './rootSagas'

const saveUserConfigFilter = createFilter(
  'userConfig',
  ['data'],
)

const persistConfig = {
  storage,
  key: 'spartacus@spartacus',
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'auth',
    'userConfig',
  ],
  // blacklist: [],
  transforms: [
    saveUserConfigFilter,
  ],
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, sagaMiddleware]

const persistedReducer = persistReducer(persistConfig, Reducers)
const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares),
)

const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

export { store, persistor }
