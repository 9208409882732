import _ from 'lodash'

import { objToQueryString } from 'helpers/functions'
import typeData from 'helpers/constants/typeData'
import axios from './axiosCustom'

const HOST = process.env.REACT_APP_MAIN_API

const headers = (apiKey, access_token = '') => {
  const objHeaders = {
    'Content-type': 'application/json',
    'x-api-key': apiKey,
    'Authorization': 'Bearer '.concat(access_token),
  }

  return objHeaders
}

export async function getReport(apiKey, accessToken, userId, params = {}) {
  const page = params.page || 1
  const { filterTerm } = params

  let queryString = userId ? `&user_id=${userId}` : ''
  queryString += `&page=${page}`
  queryString += filterTerm ? `&filter_term=${filterTerm}` : ''

  const res = await axios({
    method: 'GET',
    url: `${HOST}/report/historic?${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function postLicensePlateCreate(apiKey, accessToken, userId, licensePlate, token) {
  const data = {
    license_plate: licensePlate,
    token: token,
  }

  if(!_.isEmpty(userId)) data.user_id = userId

  const res = await axios({
    method: 'POST',
     url: `${HOST}/license/plate/vehicle`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function postChassisCreate(apiKey, accessToken, userId, chassis, token) {
  const data = {
    chassis: chassis,
    token: token,
  }

  if(!_.isEmpty(userId)) data.user_id = userId

  const res = await axios({
    method: 'POST',
     url: `${HOST}/chassis/vehicle`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function getLicensePlate(apiKey, accessToken, userId, token) {
  let queryString = (userId) ? `&user_id=${userId}` : ''
  queryString += `&token=${token}`

  const res = await axios({
    method: 'GET',
     url: `${HOST}/license/plate/vehicle/result?${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function getChassis(apiKey, accessToken, userId, token) {
  let queryString = (userId) ? `&user_id=${userId}` : ''
  queryString += `&token=${token}`

  const res = await axios({
    method: 'GET',
     url: `${HOST}/chassis/vehicle/result?${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function patchLicensePlate(apiKey, accessToken, userId, token, params) {
  const data = {
    token: token,
    bodywork: params.bodywork,
    bodywork_obs: params.bodywork_obs,
    painting: params.painting,
    security_items: params.security_items,
    tapestry: params.tapestry,
    tapestry_obs: params.tapestry_obs,
    tires: params.tires,
  }

  if(userId) data.user_id = userId

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/license/plate/vehicle`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function patchChassis(apiKey, accessToken, userId, token, params) {
  const data = {
    token: token,
    bodywork: params.bodywork,
    bodywork_obs: params.bodywork_obs,
    painting: params.painting,
    security_items: params.security_items,
    tapestry: params.tapestry,
    tapestry_obs: params.tapestry_obs,
    tires: params.tires,
  }

  if(userId) data.user_id = userId

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/chassis/vehicle`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function patchLicensePlateClientData(apiKey, accessToken, userId, token, params) {
  let data = {
    token: token,
    name: params.name,
    cpf_cnpj: params.cpf_cnpj,
    proposal: params.proposal,
    cpf_cnpj_reseller: params.cpf_cnpj_reseller,
  }

  data = _.pickBy(data, (val) => !(val === null || val === undefined))
  if(userId) data.user_id = userId

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/license/plate/contract`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function patchChassisClientData(apiKey, accessToken, userId, token, params) {
  let data = {
    token: token,
    name: params.name,
    cpf_cnpj: params.cpf_cnpj,
    proposal: params.proposal,
    cpf_cnpj_reseller: params.cpf_cnpj_reseller,
  }
  data = _.pickBy(data, (val) => !(val === null || val === undefined))
  if(userId) data.user_id = userId

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/chassis/contract`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function patchLicensePlateUploadPhoto(apiKey, accessToken, userId, token, imageUrl) {
  const data = {
    token: token,
    image_url: imageUrl,
  }

  if(userId) data.user_id = userId

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/license/plate/vehicle/photos`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function patchChassisUploadPhoto(apiKey,accessToken, userId, token, imageUrl) {
  const data = {
    token: token,
    image_url: imageUrl,
  }

  if(userId) data.user_id = userId

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/chassis/vehicle/photos`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function deleteLicensePlatePhoto(apiKey, accessToken, userId, token, imageUrl) {
  const data = {
    token: token,
    image_url: imageUrl,
  }

  if(userId) data.user_id = userId

  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/license/plate/vehicle/photos`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function deleteChassisPhoto(apiKey, accessToken, userId, token, imageUrl) {
  const data = {
    token: token,
    image_url: imageUrl,
  }

  if(userId) data.user_id = userId

  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/chassis/vehicle/photos`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function getUserConfig(apiKey, accessToken, userId) {
  const res = await axios({
    method: 'GET',
    url: `${HOST}/admin/users?user_id=${userId}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function getUsersList(apiKey, accessToken, organizationId){
  const queryString = (organizationId) ? `?organization_id=${organizationId}` : ''

  const res = await axios({
    method: 'GET',
    url: `${HOST}/admin/users/list${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function removeUser(apiKey, adminId, userId){
  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/admin/users?user_id=${userId}&admin_id=${adminId}`,
    headers: headers(apiKey),
  })

  return res
}

export async function blockUser(apiKey, accessToken, userId, block){
  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/admin/users/block?user_id=${userId}&block=${block}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function updateUser(apiKey, accessToken, user){
  const body = {
    "user_id": user.user_id,
    "user": {
      "name": user.name,
      "role": user.role,
      "permission_attributes": {
        "max_limit_access": user.requests_limit,
	    },
      "template_attributes": user.templateUser,
    },
  }

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/admin/users`,
    headers: headers(apiKey, accessToken),
    data: body,
  })

  return res
}

export async function getTemplateFromOrganization(apiKey, accessToken, organizationId){
  const res = await axios({
    method: 'GET',
    url: `${HOST}/admin/templates?organization_id=${organizationId}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function attTemplate(apiKey, accessToken, template){
  const data = {
    template_id: template.template_id,
    template: template,
  }

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/admin/templates`,
    headers: headers(apiKey, accessToken),
    data: data,
  })

  return res
}

export async function getReportConsumerAdmin(apiKey, accessToken, organizationId, dateFilter) {
  const queryString = (!_.isEmpty(dateFilter)) ? `&start_date=${dateFilter.startDate}&end_date=${dateFilter.endDate}` : ''

  const res = await axios({
    method: 'GET',
    url: `${HOST}/report/usage?organization_id=${organizationId}${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function getReportConsumerMaster(apiKey, accessToken, dateFilter) {
  const queryString = (!_.isEmpty(dateFilter)) ? `&start_date=${dateFilter.startDate}&end_date=${dateFilter.endDate}` : ''

  const res = await axios({
    method: 'GET',
    url: `${HOST}/report/usage?${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function getAuctionDetails(apiKey, accessToken, type, token, internalId) {
  const queryString = objToQueryString({
    token: token,
    internal_id: internalId,
  })

  const endpoint = typeData.licensePlate === type
    ? '/license/plate/vehicle/auctions'
    : '/chassis/vehicle/auctions'

  const res = await axios({
    method: 'GET',
    url: `${HOST}${endpoint}?${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}

export async function getPrintReport(apiKey, accessToken, type, userId, token) {
  const queryString = objToQueryString({
    user_id: userId,
    token: token,
  })

  const endpoint = typeData.licensePlate === type
    ? '/print/report/license_plate'
    : '/print/report/chassis'

  const res = await axios({
    method: 'GET',
    url: `${HOST}${endpoint}?${queryString}`,
    headers: headers(apiKey, accessToken),
    responseType: 'blob',
  })

  return res
}

export async function getAdminOrganization(apiKey, accessToken, organizationId) {
  const queryString = objToQueryString({
    organization_id: organizationId,
  })

  const endpoint = '/admin/organization'

  const res = await axios({
    method: 'GET',
    url: `${HOST}${endpoint}?${queryString}`,
    headers: headers(apiKey, accessToken),
  })

  return res
}
