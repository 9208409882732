import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { MaskInput } from 'components/MaskedInput'

import {
  dataRequestedClear,
  postData,
  setType,
  setTerm,
} from 'redux/dataRequested/actions'

import {
  fetchUserConfig,
  setCurrentPage,
} from 'redux/userConfig/actions'

import PanelHeader from 'components/PanelHeader'
import PanelClientData from 'components/PanelClientData'
import PanelVehicleData from 'components/PanelVehicleData'
import PanelVehicleAuctions from 'components/PanelVehicleAuctions'
import PanelVehicleEvaluation from 'components/PanelVehicleEvaluation'
import PanelVehicleState from 'components/PanelVehicleState'
import PanelVehiclePhotos from 'components/PanelVehiclePhotos'
import PanelDealer from 'components/PanelDealer'
import ListItems from 'components/ListItems'
import ListUsers from 'components/ListUsers'

import { verifyToken } from 'helpers/functions'
import { getPrintReport } from 'services/apiMain'

import { TitleNormal, TitleBold } from 'components/styledComponents'
import typeData from 'helpers/constants/typeData'
import styles from './styles'

const Dashboard = props => {
  const classes = styles(props)

  const dispatch = useDispatch()
  const history = useHistory()

  const apiKey = useSelector((state) => state.auth.api_key)
  const dataReq = useSelector(state => state.dataRequested.data)
  const dataReqType = useSelector(state => state.dataRequested.type)
  const tokenSelected = useSelector(state => state.dataRequested.tokenSelected)
  const dataReqLoading = useSelector(state => state.dataRequested.load)
  const dataReqShowing = useSelector(state => state.dataRequested.showing)
  const termValid = useSelector(state => state.dataRequested.termValid)
  const termCurrent = useSelector(state => state.dataRequested.term)
  const userConfig = useSelector(state => state.userConfig.data)
  const currentPage = useSelector(state => state.userConfig.currentPage)
  const userSelected = useSelector(state => state.listUsers.userHistoryShow)
  const accessToken = useSelector(state => state.auth.access_token)
  const sub = useSelector(state => state.auth.sub)
  const refreshtoken = useSelector(state => state.auth.refresh_token)

  const [printLoading, setPrintLoading] = useState(false)

  useEffect(() => {
    verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    dispatch(fetchUserConfig())
    if(currentPage !== 'dashboard') dispatch(setCurrentPage('dashboard'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessToken, sub, refreshtoken, currentPage])

  const handleChange = (_name = null) => event => {
    dispatch(setTerm(event.target.value.toUpperCase()))
  }

  const handleChangeType = (event) => {
    dispatch(setType(event.target.value))
  }

  const handleClickPost = async () => {
    await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    dispatch(postData())
  }

  const handleClickClear = () => {
    dispatch(dataRequestedClear())
  }

  const handlePrint = async () => {
    setPrintLoading(true)

    let res = null
    try {
      res = await getPrintReport(
        apiKey,
        accessToken,
        dataReqType,
        _.get(userConfig, 'user_id') === userSelected.user_id ? null : userSelected.user_id,
        tokenSelected,
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao gerar impressão'
      toast.error(messageError)

      setPrintLoading(false)
      return
    }

    const blob = new Blob([res.data], { type: res.headers['content-type'] })
    const blobUrl = window.URL.createObjectURL(blob)

    try {
      const newWindow = window.open('/loading')
      newWindow.addEventListener(
        'load',
        () => {
          newWindow.location = blobUrl
        },
        false,
      )
    } catch (err) {
      toast.warning('É necessário habilitar Popups no navegador para imprimir o laudo.')
    }

    setPrintLoading(false)
  }

  const renderRadioGroupType = () => {
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Buscar veículo por:</FormLabel>
        <RadioGroup
          aria-label="buscar por"
          className={classes.group}
          value={dataReqType}
          onChange={handleChangeType}
          row={true}
        >
          <FormControlLabel value={typeData.licensePlate} control={<Radio />} label="Placa" />
          <FormControlLabel value={typeData.chassis} control={<Radio />} label="Chassi" />
        </RadioGroup>
      </FormControl>
    )
  }

  const renderItemSelected = () => {
    if (!dataReqShowing) return null

    return (
      <Card className={classes.paper} style={{ marginBottom: 20 }}>
        <div className={classes.rowContainer}>
          { _.isEmpty(dataReq)
            ? <CircularProgress />
            : (
              <div style={{ display: 'flex', 'justifyContent': 'space-between' }}>
                <div>
                  <TitleBold>
                    {
                      dataReq.request_type === typeData.licensePlate
                      ? dataReq.license_plate
                      : dataReq.chassis
                    }
                  </TitleBold>
                  <TitleNormal>
                    {
                      dataReq.request_type === typeData.licensePlate
                      ? 'Placa'
                      : 'Chassi'
                    }
                  </TitleNormal>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.buttonClear}
                    onClick={handleClickClear}
                  >
                    Limpar
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </Card>
    )
  }

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={3}>
            <div>
              {(_.get(userConfig, 'role') !== 'common') && <ListUsers />}
              {renderItemSelected()}

              {(!dataReqShowing && (_.get(userConfig, 'role') === 'common' || _.get(userConfig, 'user_id') === userSelected.user_id )) && (
                <Card className={classes.paper}>
                  <div className={classes.rowContainer}>
                    {renderRadioGroupType()}
                    <MaskInput
                      className={classes.textField}
                      label={`${dataReqType === typeData.licensePlate ? 'Placa' : 'Chassi'} do Veículo`}
                      onChange={handleChange()}
                      name="none"
                      variant="outlined"
                      value={termCurrent}
                      disabled={false} // TODO: set from state
                      errorMsg={termValid ? null : "Valor Inválido"}
                    />

                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={handleClickPost}
                      disabled={!termValid || dataReqLoading}
                    >
                      Solicitar
                    </Button>
                  </div>
                </Card>
              )}
            </div>

            <ListItems />
          </Grid>

          <Grid item xs={12} md={true} lg={true} className="container-to-print">
            <PanelHeader />
            <div className={classes.dividerSpace} />
            <PanelClientData />
            <div className={classes.dividerSpace} />
            <PanelVehicleData />
            <div className={classes.dividerSpace} />
            <PanelVehicleEvaluation />
            <div className={classes.dividerSpace} />
            <PanelVehicleAuctions />
            <div className={classes.dividerSpace} />
            <PanelVehicleState />
            <div className={classes.dividerSpace} />
            <PanelVehiclePhotos />
            <div className={classes.dividerSpace} />
            <PanelDealer />

            {dataReqShowing && !_.isEmpty(dataReq) && (
              <div className={classNames(classes.containerButtonPrint, 'no-print')}>
                <Button
                  variant="contained"
                  className={classes.buttonPrint}
                  onClick={handlePrint}
                  disabled={printLoading || dataReqLoading}
                >
                  Imprimir
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

export default Dashboard
