import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.main,
    backgroundImage: 'url(assets/images/background/bg_login.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    width: '40%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
    color: theme.palette.primary.lighter,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      alignItems: 'center',
      padding: '20px 0',
    },
  },
  titleSidebarContainer: {
    marginBottom: 50,
    position: 'absolute',
    bottom: 0,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 'initial',
    },
  },
  titleSidebar: {
    fontSize: 50,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  subtitleSidebar: {
    fontSize: 18,
  },
  logo: {
    width: 300,

    [theme.breakpoints.down('xs')]: {
      width: 230,
    },
  },
  main: {
    height: '100%',
    width: '60%',
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      padding: 10,
    },
  },
  title: {
    fontSize: 30,
    color: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  textField: {
    width: 400,
    marginBottom: 30,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
    },
  },
  buttonContainer: {
     width: 300,

    [theme.breakpoints.down('sm')]: {
       width: '100%',
    },
  },
  button: {
    width: 'inherit',
    height: '50px',
    borderRadius: 30,
    marginTop: '50px',
    marginBottom: 20,

    '&:hover':{
      backgroundColor: theme.palette.primary.hover,
    },
  },
  progress: {
    height: '25px !important',
    margin: theme.spacing(1),
    width: '25px !important',
  },
  error: {
    color: 'red',
    fontSize: 16,
  },
  noDisplay: {
    display: 'none',
  },
}))

export default styles
