import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  container:{
  },
  title: {
    '@media print': {
      fontSize: theme.report.fontSizeTitle,
    },
  },
  containerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: '5px 0',

    '@media print': {
      margin: theme.report.marginFieldInPrint,
    },

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'initial',
      },
    },
  },
  containerContentText: {
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
      },
    },
  },
  containerContentTextSmall: {
    width: '25%',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
      },
    },
  },
  containerContentTextLarge: {
    width: '100%',
  },
  containerFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  btnAuctionDetails: {
    marginLeft: 'auto',
    padding: 0,
    backgroundColor: '#9083ff',
    color: '#fff',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#6c5bff',
    },
  },
  description: {
    fontSize: theme.report.fontSize,
    fontWeight: 'bold',
    marginLeft: 15,
    marginRight: 30,

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        marginLeft: 10,
        marginRight: 10,
      },
    },

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  checkItemContainer: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'initial',
      },
    },
  },
  checkItem: {
    border: '1px solid',
    fontSize: 20,
    lineHeight: 1,
    width: 25,
    height: 25,
    textAlign: 'center',
    display: 'inline-block',
    marginRight: 5,
    marginLeft: 20,
    verticalAlign: 'bottom',

    '@media print': {
      width: 15,
      height: 15,
      fontSize: 12,
    },
  },
  value: {
    fontSize: theme.report.fontSize,

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  valueCustom: {
    display: 'inline-block',
  },
  hl: {
    border: '1px dashed #aaa',
  },
  vl: {
    borderLeft: '2px solid #aaa',
    height: 20,
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'none',
      },
    },
  },
}))

export default styles
