import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  containerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: '5px 0',

    '@media print': {
      margin: theme.report.marginFieldInPrint,
    },

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'initial',
      },
    },
  },
  containerContentText: {
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
      },
    },
  },
  containerContentTextSmall: {
    width: '25%',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
      },
    },
  },
  containerContentTextLarge: {
    width: '100%',
  },
  containerFlex: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  description: {
    fontSize: theme.report.fontSize,
    fontWeight: 'bold',
    marginLeft: 15,
    marginRight: 30,

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        marginLeft: 10,
        marginRight: 10,
      },
    },

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  value: {
    fontSize: theme.report.fontSize,

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  hl: {
    border: '1px dashed #aaa',
  },
  vl: {
    borderLeft: '2px solid #aaa',
    height: 20,
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'none',
      },
    },
  },
  containerImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  containerImage: {
    position: 'relative',
    margin: 10,

    '@media print': {
      margin: '0 5px',
    },
  },
  image: {
    height: 200,
    width: 200,

    '@media print': {
      height: 150,
      width: 150,
    },
  },
}))

export default styles
