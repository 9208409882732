import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    padding: 20,
    background: theme.palette.primary.bg,
    height: '100%',
  },
  paper:{
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
  },
  expansion: {
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    marginBottom: 30,
  },
  button: {
    backgroundColor: theme.palette.third.main,
    fontSize: 20,
    color: '#FFF',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: theme.palette.third.hover,

    },
  },
  containerButtonPrint: {
    marginTop: 80,
  },
  buttonPrint: {
    backgroundColor: theme.palette.third.main,
    fontSize: 20,
    color: '#FFF',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: theme.palette.third.hover,

    },
  },
  buttonClear: {
    fontSize: 18,
    color: '#000',
    border: 'none',
  },
  buttonAction: {
    fontSize: 18,
    color: '#000',
    border: 'none',
    marginBottom: 10,
  },
  dividerSpace: {
    padding: 10,

    '@media print': {
      padding: 4,
    },
  },
}))

export default styles
