import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import styles from './styles'

const PanelDealer = () => {
  const classes = styles()

  const dataReq = useSelector(state => state.dataRequested.data)
  const userConfig = useSelector(state => state.userConfig.data)

  const reportLogoUrlAssigned = _.get(userConfig, 'organization.report_logo_url_assigned')

  return (
    <div className={classNames(classes.container, 'header-to-print')}>
      <div className={classes.containerImage}>
        <img
          src={reportLogoUrlAssigned}
          alt="logo"
          className={classes.image}
        />
      </div>
      <div className={classes.containerTitle}>
        <h2 className={classes.title}>TERMO DE AVALIAÇÃO DE VEÍCULO</h2>
        <p className={classes.subtitle}>
          <span>Proposta/Contrato:</span>
          {' '}
          <span>{_.get(dataReq, 'proposal')}</span>
        </p>
      </div>
    </div>
  )
}

export default PanelDealer
