import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { isCPF, isCNPJ, formatToCPF, formatToCNPJ } from 'brazilian-values'
import moment from 'moment'
import classNames from 'classnames'

import {
  Button,
  TextField,
} from '@material-ui/core'

import {
  patchClientData,
} from 'redux/dataRequested/actions'

import { MaskInput } from 'components/MaskedInput'

import styles from './styles'

const PanelHeader = () => {
  const classes = styles()
  const dispatch = useDispatch()

  const dataReq = useSelector(state => state.dataRequested.data)

  const [values, setValues] = useState({
    cpf_cnpj_reseller: _.get(dataReq, 'cpf_cnpj_reseller') || '',
  })
  const [isEditCpfCnpjReseller, setIsEditCpfCnpjReseller] = useState(false)

  useEffect(() => {
    setValues({
      cpf_cnpj_reseller: _.get(dataReq, 'cpf_cnpj_reseller') || '',
    })
  }, [dataReq])

  const callSetIsEditField = (fieldName, value) => {
    switch (fieldName) {
      case 'cpf_cnpj_reseller':
        setIsEditCpfCnpjReseller(value)
        break
      default:
        break
    }
  }

  const isEditField = (fieldName) => {
    switch (fieldName) {
      case 'cpf_cnpj_reseller':
        return isEditCpfCnpjReseller
      default:
        break
    }
  }

  const handleChange = (fieldName) => (_event, rawValue) => {
    setValues({ ...values, [fieldName]: rawValue })
  }

  const handleClickEdit = (fieldName) => {
    callSetIsEditField(fieldName, true)
  }

  const handleClickSave = (fieldName) => {
    callSetIsEditField(fieldName, false)
    dispatch(patchClientData({ [fieldName]: values[fieldName] }))
  }

  const handleClickCancel = (fieldName) => {
    callSetIsEditField(fieldName, false)
    setValues({ ...values, [fieldName]: _.get(dataReq, fieldName) || '' })
  }

  const renderButtonsEdit = (fieldName) => {
    if (_.isEmpty(dataReq)) return null

    let buttonElem = null

    if (!isEditField(fieldName)) {
      buttonElem = (
        <React.Fragment>
          <Button
            variant="contained"
            size="small"
            className={classes.buttonEdit}
            onClick={() => handleClickEdit(fieldName)}
          >
            Editar
          </Button>
        </React.Fragment>
      )
    } else {
      const value = _.get(values, `${fieldName}`) || ''

      buttonElem = (
        <React.Fragment>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.buttonEdit, classes.buttonColorCancel)}
            onClick={() => handleClickCancel(fieldName)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.buttonEdit, classes.buttonColorSuccess)}
            onClick={() => handleClickSave(fieldName)}
            disabled={!(fieldName === 'cpf_cnpj_reseller' ? (isCPF(value) || isCNPJ(value)) : true)}
          >
            Salvar
          </Button>
        </React.Fragment>
      )
    }

    return (
      <div className={classNames(classes.buttonContainer, 'no-print')}>
        {buttonElem}
      </div>
    )
  }

  const renderValue = (fieldName, type = 'text') => {
    const value = _.get(values, `${fieldName}`) || ''

    if (!isEditField(fieldName)) {
      let valueFormatted = value
      switch (type) {
        case 'cpf_cnpj':
          valueFormatted = isCPF(value) ? formatToCPF(value) : formatToCNPJ(value)
          break
        default:
          break
      }

      return <span className={classes.value}>{valueFormatted}</span>
    }

    if (type === 'cpf_cnpj') {
      if (!isCPF(value) && value.length >= 11) {
        return (
          <MaskInput
            name="cnpj"
            onChange={handleChange(fieldName)}
            value={value}
            errorMsg={isCNPJ(value) ? null : "Valor Inválido"}
          />
        )
      }

      return (
        <MaskInput
          name="cpf"
          onChange={handleChange(fieldName)}
          value={value}
          errorMsg={isCPF(value) ? null : "Valor Inválido"}
        />
      )
    }

    return (
      <TextField
        onChange={e => handleChange(fieldName)(e, e.target.value)}
        value={value}
      />
    )
  }

  const renderCpfCnpjReseller = () => {
    return renderValue('cpf_cnpj_reseller', 'cpf_cnpj')
  }

  return (
    <div className={classNames(classes.container)}>
      <div className={classNames(classes.subtitle, classes.reseller)}>
        <span>CPF/CNPJ do revendedor:</span>
        <div className={classes.valueContainer}>
          <span className={classes.resellerValue}>{renderCpfCnpjReseller()}</span>
          {renderButtonsEdit('cpf_cnpj_reseller')}
        </div>
      </div>
      <div className={classNames(classes.subtitle, classes.dateTime)}>
        <span>Data | Hora Emissão:</span>
        {' '}
        <span>{moment().format('DD/MM/YYYY | HH:mm:SS')}</span>
      </div>
    </div>
  )
}

export default PanelHeader
