import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import _ from 'lodash'
import moment from 'moment'

import { verifyToken } from 'helpers/functions'

import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from '@material-ui/core'

import {
  checkServicesStatus,
  selectItem,
  dataRequestedClear,
} from 'redux/dataRequested/actions'

import {
  fetchListItems,
  listItemsClear,
} from 'redux/listItems/actions'

import { MaskInput } from 'components/MaskedInput'
import styles from './styles'



const ListItems = () => {
  const classes = styles()

  const dispatch = useDispatch()
  const history = useHistory()

  const listItems = useSelector(state => state.listItems)
  const tokenSelected = useSelector(state => state.dataRequested.tokenSelected)
  const userConfig = useSelector(state => state.userConfig.data)
  const userSelected = useSelector(state => state.listUsers.userHistoryShow)
  const accessToken = useSelector(state => state.auth.access_token)
  const sub = useSelector(state => state.auth.sub)
  const refreshtoken = useSelector(state => state.auth.refresh_token)

  const [filterText, setFilterText] = useState('')

  const callFilter = async (options) => {
    await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    dispatch(listItemsClear())
    dispatch(fetchListItems(options))
  }
  const [debouncedCallFilter] = useState(() => _.debounce(callFilter, 800))

  const handleLoadItem = async (token, term, type) => {
    if (token === tokenSelected) return

    await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    await dispatch(dataRequestedClear())
    await dispatch(selectItem(token, term, type))
    await dispatch(checkServicesStatus())
  }

  const handleChangePageListItems = async (_event, page) => {
    if (listItems.total === 0) return

    await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    dispatch(listItemsClear())
    const options = {
      page: page + 1,
      filterTerm: filterText,
    }
    if (userConfig.user_id !== userSelected.user_id) options.user_id = userSelected.user_id

    dispatch(fetchListItems(options))
  }

  useEffect(() => {
    dispatch(listItemsClear())
    dispatch(dataRequestedClear())

    const options = { page: 1 }
    if (userConfig.role !== 'common') {
      options.user_id = userSelected.user_id
    }

    dispatch(fetchListItems(options))
  }, [dispatch, userConfig.role, userSelected.user_id])

  const handleInputFilterText = (event) => {
    const term = event.target.value
    const options = { filterTerm: term}
    if (userConfig.user_id !== userSelected.user_id) options.user_id = userSelected.user_id
    debouncedCallFilter(options)
    setFilterText(term)
  }

  const renderListItems = (token) => {
    const itemData = _.find(listItems.items, ['token', token])
    if (_.isEmpty(itemData)) return null

    // const someNeedInputs = !_.isEmpty(itemData.service_infos) && _.some(itemData.service_infos, (val, _key) => val.status === 'NEED_INPUTS')
    // const someProcessing = !_.isEmpty(itemData.service_infos) && _.some(itemData.service_infos, (val, _key) => val.status === 'PROCESSING')
    // const someSlowProcessing = !_.isEmpty(itemData.service_infos) && _.some(itemData.service_infos, (val, _key) => val.status === 'PROCESSING' && val.slow_processing === true)
    // const someConsists =!_.isEmpty(itemData.service_infos) && _.some(itemData.service_infos, (val, _key) => val.consists === true)
    // const allProcessed = !_.isEmpty(itemData.service_infos) && _.every(itemData.service_infos, (val, _key) => val.status === 'PROCESSED')

    let elemStatus = null
    // if (someNeedInputs) {
    //   elemStatus = <KeyboardIcon fontSize="small" style={{ color: 'gray' }} />
    // } else if (someProcessing) {
    //   elemStatus = <AutorenewIcon fontSize="small" style={{ color: someSlowProcessing ? '#ed9900' : 'gray' }} />
    // } else if (someConsists) {
    //   elemStatus = <ErrorIcon fontSize="small" style={{ color: '#C5184D' }} />
    // } else if (allProcessed) {
    //   elemStatus = <CheckCircleIcon fontSize="small" style={{ color: '#01A901' }} />
    // }

    return (
      <div className={classes.containerListItem}>
        <div style={{ width: '6.5rem', fontSize: '1.1rem' }}>
          {itemData.term}
        </div>
        {elemStatus}
        <span style={{ marginLeft: 30 }}>{moment(itemData.created_at).format('DD-MM-YYYY HH:mm')}</span>
      </div>
    )
  }

  const renderInputFilterText = () => (
    <MaskInput
      className={classes.inputFilterText}
      label="Buscar"
      name="none"
      onChange={handleInputFilterText}
      value={filterText}
    />
  )

  return (
    <Card className={classes.paper} style={{ marginTop: 20 }}>
      <CardHeader className={classes.header} title={<span className={classes.headerText}>Histórico</span>} />
      <div className={classes.containerInputsFilter}>
        {renderInputFilterText()}
      </div>
      <Divider />
      <CardContent classes={{ root: classes.cardItemData }}>
        <div className={classes.containerItemData}>
          {listItems.load && (
            <CircularProgress style={{marginTop: 20}} />
          )}
          <div>
            <List>
              {listItems.items.map((item, i) => (
                <ListItem
                  className={classes.listItems}
                  button
                  key={i.toString()}
                  disabled={item.token === tokenSelected}
                  selected={item.token === tokenSelected}
                  onClick={() => handleLoadItem(item.token, item.term, item.request_type)}
                >
                  <ListItemText>{renderListItems(item.token)}</ListItemText>
                </ListItem>
              ))}
            </List>
            <TablePagination
              className={classes.listItemPagination}
              component="nav"
              page={listItems.currentPage - 1}
              rowsPerPage={listItems.perPage}
              rowsPerPageOptions={[]}
              count={listItems.total}
              onChangePage={handleChangePageListItems}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ListItems
