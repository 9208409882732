import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import styles from './styles'

const PanelVehicleData = () => {
  const classes = styles()

  const dataReq = useSelector(state => state.dataRequested.data)
  const templateConfig = useSelector(state => state.userConfig.template)

  if (_.get(templateConfig, 'vehicle_data') !== true) return null

  return (
    <div className={classNames(classes.container)}>
      <h1 className={classes.title}>Dados do Veículo</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Placa:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.license_plate')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>UF Placa:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.license_plate_uf')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Renavam:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.renavam')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Chassi:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.chassis')}</span>
        </div>

      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Marca/Modelo:</span>
          <span className={classes.value}>{`${_.get(dataReq, 'vehicle_data.brand') || ''} ${_.get(dataReq, 'vehicle_data.model') || ''}`}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Ano Fabricação:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.manufacture_year')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Ano Modelo:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.model_year')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Cor:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.color')}</span>
        </div>

        <div className={classes.containerContentText}>
          <span className={classes.vl} />
          <span className={classes.description}>N° Motor:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.motor_number')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Combustível:</span>
          <span className={classes.value}>{_.get(dataReq, 'vehicle_data.fuel')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </div>
  )
}

export default PanelVehicleData
