import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerImage: {
    minHeight: 80,
    minWidth: 100,
    height: 80,
    width: 100,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        marginRight: 5,
      },
    },

    '@media print': {
      minHeight: 65,
      minWidth: 81,
      height: 65,
      width: 81,
    },
  },
  image: {
    width: '100%',
    maxHeight: '100%',
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  title: {
    fontSize: 26,
    fontWeight: 'normal',

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        fontSize: 20,
      },
    },

    '@media print': {
      fontSize: 22,
    },
  },
  subtitle: {
    fontSize: 16,

    '@media print': {
      fontSize: 14,
    },
  },
}))

export default styles
