import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'

import {
  Button,
  TextField,
} from '@material-ui/core'

import {
  patchData,
} from 'redux/dataRequested/actions'

import styles from './styles'

const PanelVehicleState = () => {
  const classes = styles()

  const dispatch = useDispatch()
  const dataReq = useSelector(state => state.dataRequested.data)
  const dataReqFail = useSelector(state => state.dataRequested.fail)
  const templateConfig = useSelector(state => state.userConfig.template)

  const [isEdit, setIsEdit] = useState(false)
  const [values, setValues] = useState(_.get(dataReq, 'vehicle_state') || {})

  useEffect(() => {
    setValues(_.get(dataReq, 'vehicle_state') || {})
  }, [dataReq, dataReqFail])

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleClickEdit = () => {
    setIsEdit(true)
  }

  const handleClickSave = () => {
    setIsEdit(false)
    dispatch(patchData(values))
  }

  const handleClickCancel = () => {
    setIsEdit(false)
    setValues(_.get(dataReq, 'vehicle_state') || {})
  }

  const renderValue = (fieldName, inputType = 'text', textAreaRows = 4, textAreaLength = 500) => {
    const value = _.get(values, `${fieldName}`) || ''

    if (!isEdit) {
      return <span className={classes.value}>{value}</span>
    }

    if (inputType === 'text') {
      return (
        <input
          className={classes.input}
          maxLength="20"
          onChange={handleChange(fieldName)}
          value={value}
        />
      )
    }

    if (inputType === 'select') {
      const options = ['Péssimo', 'Regular', 'Bom', 'Ótimo']
      return (
        <select onChange={handleChange(fieldName)} value={value}>
          <option value="" hidden={true} />
          {
            _.map(options, (option, i) => (
              <option key={i.toString()} value={option}>{option}</option>
            ))
          }
        </select>
      )
    }

    return (
      <TextField
        className={classes.textArea}
        inputProps={{
          maxLength: textAreaLength,
        }}
        maxLength={textAreaLength}
        multiline={true}
        onChange={handleChange(fieldName)}
        rows={textAreaRows}
        value={value}
      />
    )
  }

  const renderButtonsEdit = () => {
    if (_.isEmpty(dataReq)) return null

    let buttonElem = null

    if (!isEdit) {
      buttonElem = (
        <div>
          <Button
            variant="contained"
            size="small"
            className={classes.buttonEdit}
            onClick={handleClickEdit}
          >
            Editar
          </Button>
        </div>
      )
    } else {
      buttonElem = (
        <div>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.buttonEdit, classes.buttonColorCancel)}
            onClick={handleClickCancel}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.buttonEdit, classes.buttonColorSuccess)}
            onClick={handleClickSave}
          >
            Salvar
          </Button>
        </div>
      )
    }

    return (
      <div className="no-print">
        {buttonElem}
      </div>
    )
  }

  if (_.get(templateConfig, 'vehicle_state') !== true) return null

  return (
    <div className={classNames(classes.container)}>
      <div className={classes.containerTitle}>
        <h1 className={classes.title}>Estado do Veículo</h1>
        {renderButtonsEdit()}
      </div>

      <div className={classes.containerLine}>
        <span className={classes.subtitle}>
          Resultado da avaliação do estado de conservação do veículo
        </span>
      </div>

      <div className={classes.containerLine}>
        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Lataria:</span>
          {renderValue('bodywork', 'select')}
        </div>
        <div className={classes.containerContentTextMedium}>
          <span className={classes.description}>Obs:</span>
          {renderValue('bodywork_obs', 'textarea', 3, 280)}
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Tapeçaria:</span>
          {renderValue('tapestry', 'select')}
        </div>
        <div className={classes.containerContentTextMedium}>
          <span className={classes.description}>Obs:</span>
          {renderValue('tapestry_obs', 'textarea', 3, 280)}
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Pintura:</span>
          {renderValue('painting', 'select')}
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Pneus:</span>
          {renderValue('tires', 'select')}
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentLarge}>
          <span className={classes.description}>Itens de segurança:</span>
          {renderValue('security_items', 'textarea', 4, 460)}
        </div>
      </div>

      <hr className={classes.hl} />
    </div>
  )
}

export default PanelVehicleState
