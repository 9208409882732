import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import {
  Button,
  Fab,
} from '@material-ui/core'
import {
  DeleteOutlined as DeleteOutlinedIcon,
} from '@material-ui/icons'

import { toast } from 'react-toastify'

import { uploadPhoto, deletePhoto } from 'redux/dataRequested/actions'
import { generateToken } from 'helpers/functions'
import { fileManagerUploadImage, fileManagerGetUrlImage } from 'services/apiFileManager'

import styles from './styles'

const MAX_IMAGES = 4

const removeQueryFromUrl = (url) => url.split('?')[0]

const PanelVehiclePhotos = () => {
  const classes = styles()

  const dispatch = useDispatch()
  const userId = useSelector(state => state.auth.sub)
  const dataReq = useSelector(state => state.dataRequested.data)
  const templateConfig = useSelector(state => state.userConfig.template)

  const vehiclePhotos = _.get(dataReq, 'vehicle_photos') || []

  const uploadImage = async (imageFile) => {
    const token = generateToken()

    let res = null
    try {
      res = await fileManagerUploadImage(userId, imageFile, token)
      res = await fileManagerGetUrlImage(userId, res.data.file_id, token)
    } catch (err) {
      toast.error('Não foi possível salvar a imagem, tente mais tarde')
      return
    }
    const imageUrl = removeQueryFromUrl(res.data.url)
    dispatch(uploadPhoto(imageUrl))
  }

  const handleAddPhoto = (event) => {
    if (vehiclePhotos.length >= MAX_IMAGES) return
    if (event.target.files.length === 0) return

    const file = event.target.files[0]
    const { size } = file
    if (size >  5 * 1000 * 1000) {
      toast.error('A imagem deve ter no máximo 5MB')
      return
    }

    const fileName = file.name
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1)
    if (!['jpeg', 'jpg', 'png'].includes(ext.toLowerCase())) {
      toast.error('A imagem deve ter um dos formatos: ".jpeg", ".jpg" ou ".png"')
      return
    }

    uploadImage(file)
  }

  const handleDeletePhoto = (imageUrl) => (_event) => {
    const imageUrlTreated = removeQueryFromUrl(imageUrl)
    dispatch(deletePhoto(imageUrlTreated))
  }

  const renderImages = () => {
    return vehiclePhotos.map((photo, i) => {
      return (
        <div key={i.toString()} className={classes.containerImage}>
          <div className={classNames(classes.containerButtonDeleteImage, 'no-print')}>
            <Fab
              className={classes.buttonDeleteImage}
              size="small"
              aria-label="deletar"
              onClick={handleDeletePhoto(photo.image_url)}
            >
              <DeleteOutlinedIcon />
            </Fab>
          </div>
          <img
            src={photo.image_url_assigned}
            alt="imagem"
            className={classes.image}
          />
        </div>
      )
    })
  }

  if (_.get(templateConfig, 'vehicle_photos') !== true) return null

  return (
    <div className={classNames(classes.container)}>
      <h1 className={classes.title}>Fotos do Veículo</h1>

      <div className={classes.containerImages}>
        {renderImages()}

        { !_.isEmpty(dataReq) && (
          <label htmlFor="icon-button-file" className="no-print">
            <input
              color="primary"
              accept="image/png, image/jpeg, image/jpg"
              type="file"
              onChange={handleAddPhoto}
              id="icon-button-file"
              style={{ display: 'none' }}
            />
            { (vehiclePhotos.length < MAX_IMAGES) && (
            <Button
              variant="contained"
              component="span"
              className={classes.buttonAddPhoto}
              size="small"
            >
              Adicionar Foto
            </Button>
          )}
          </label>
        )}
      </div>
    </div>
  )
}

export default PanelVehiclePhotos
