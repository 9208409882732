import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  container:{
  },
  title: {
    '@media print': {
      fontSize: theme.report.fontSizeTitle,
    },
  },
  containerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: '5px 0',

    '@media print': {
      margin: theme.report.marginFieldInPrint,
    },

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'initial',
      },
    },
  },
  containerContentText: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        width: '100%',
      },
    },
  },
  containerContentTextLarge: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  description: {
    fontSize: theme.report.fontSize,
    fontWeight: 'bold',
    marginLeft: 15,
    marginRight: 30,

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        marginLeft: 10,
        marginRight: 10,
      },
    },

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  value: {
    fontSize: theme.report.fontSize,
    textAlign: 'justify',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        marginLeft: 10,
        marginRight: 10,
      },
    },

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  hl: {
    border: '1px dashed #aaa',
  },
  vl: {
    borderLeft: '2px solid #aaa',
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'none',
      },
    },
  },
  buttonContainer: {
    marginLeft: 60,
    marginTop: 2,
    marginBottom: 2,

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
         marginLeft: 10,
      },
    },
  },
  buttonEdit: {
    backgroundColor: '#9083ff',
    color: '#fff',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#6c5bff',
    },
  },
  buttonColorSuccess: {
    backgroundColor: '#00ac00',
    '&:hover': {
      backgroundColor: '#019101',
    },
  },
  buttonColorCancel: {
    backgroundColor: '#e0e0e0',
    color: '#000',
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#d5d5d5',
    },
  },
  textField: {
    width: 250,
  },
}))

export default styles
