import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: theme.report.fontSize,

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  reseller: {
    display: 'flex',
  },
  resellerValue: {
    marginLeft: 10,
  },
  dateTime: {
    visibility: 'hidden',
  },
  buttonContainer: {
    marginLeft: 60,
    marginTop: 2,
    marginBottom: 2,

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
         marginLeft: 10,
      },
    },
  },
  buttonEdit: {
    backgroundColor: '#9083ff',
    color: '#fff',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#6c5bff',
    },
  },
  buttonColorSuccess: {
    backgroundColor: '#00ac00',
    '&:hover': {
      backgroundColor: '#019101',
    },
  },
  buttonColorCancel: {
    backgroundColor: '#e0e0e0',
    color: '#000',
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#d5d5d5',
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  value: {
    fontSize: theme.report.fontSize,
    textAlign: 'justify',
    whiteSpace: 'nowrap',

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
}))

export default styles
