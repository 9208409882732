import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import {
  setUserHistoryShow,
  fetchListUsers,
  listUsersClear,
} from 'redux/listUsers/actions'

import {
  listItemsClear,
} from 'redux/listItems/actions'

import { verifyToken } from 'helpers/functions'
import typeDataConstants from 'helpers/constants/typeData'

import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core'

import styles from './styles'

const ListUsers = () => {
    const classes = styles()

    const dispatch = useDispatch()
    const history = useHistory()

    const listUsers = useSelector(state => state.listUsers)
    const userSelected = useSelector(state => state.listUsers.userHistoryShow)
    const accessToken = useSelector(state => state.auth.access_token)
    const sub = useSelector(state => state.auth.sub)
    const refreshtoken = useSelector(state => state.auth.refresh_token)
    const userLogged = useSelector(state => state.userConfig.data)

    const initialOptionValue = 'initialOption'
    const [optionSelected, setOptionSelected] = React.useState(initialOptionValue)

    useEffect(() => {
      dispatch(listUsersClear())
      if(userLogged.role === 'admin'){
        dispatch(fetchListUsers())
      } else if (userLogged.role === typeDataConstants.ROLE.MASTER) {
        const organization_id = _.get(userLogged, 'organization.organization_id')
        setOptionSelected(organization_id)
        dispatch(fetchListUsers({ organization_id: organization_id }))
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userLogged.role])

    const handleUserSelect = async (event) => {
      await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
      const user = _.find(listUsers.users, ['user_id', event.target.value])
      dispatch(setUserHistoryShow(user))
    }

    const handleOrgSelect = async (event) => {
      dispatch(listUsersClear())
      const { value } = event.target

      if(value === initialOptionValue){
        dispatch(listItemsClear())
        setOptionSelected(initialOptionValue)
        return
      }

      const org = _.find(userLogged.managed_organizations, ['organization_id', event.target.value])
      setOptionSelected(org.organization_id)
      dispatch(fetchListUsers({organization_id: org.organization_id}))
    }

    const renderItemMenu = (data, key = '') => {
      return (
        <MenuItem key={key} value={data.user_id}>
          <div className={classes.cardMenuItem}>
            <p className={classes.menuFirstLine}>
              <span className={classes.menuItemName}>{`${data.name} (${data.role})`}</span>
              <span className={classes.menuItemTypeConsults}>
                {data.total_requests_count}
                {' '}
                consultas
              </span>
            </p>
            <p className={classes.menuItemEmail}>{data.email}</p>
          </div>
        </MenuItem>
      )
    }

    return (
      <React.Fragment>
        {(userLogged.role === 'master' && !userLogged.loading) && (
          <React.Fragment>
            <Card className={classes.paper} style={{ marginBottom: 20}}>
              <CardHeader
                className={classes.header}
                title={(
                  <span className={classes.headerText}>
                    Organização
                  </span>
                  )}
              />
              <Divider />
              <CardContent classes={{ root: classes.cardItemData}}>
                <div>
                  <Select
                    className={classes.selectUser}
                    onChange={handleOrgSelect}
                    value={optionSelected}
                  >
                    <MenuItem value={initialOptionValue}>
                      Selecione uma organização...
                    </MenuItem>
                    {userLogged.managed_organizations.map((item, i) => {
                    return (
                      <MenuItem key={i.toString()} value={item.organization_id}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                  </Select>
                </div>
              </CardContent>
            </Card>
          </React.Fragment>
        )}

        <Card className={classes.paper} style={{marginBottom: 20}}>
          <CardHeader
            className={classes.header}
            title={(
              <span className={classes.headerText}>
                Usuários
              </span>
            )}
          />
          <Divider />
          <CardContent classes={{ root: classes.cardItemData}}>
            <div>
              {listUsers.load && <CircularProgress style={{marginTop: 10}} /> }
              {listUsers.users.length > 0
               && (
               <Select
                 className={classes.selectUser}
                 onChange={handleUserSelect}
                 value={userSelected.user_id ? userSelected.user_id  : ""}
               >
                 {listUsers.users.map((item, i) => {
                    return renderItemMenu(item, i.toString())
                  })}
               </Select>
                  )
            }
              {(!listUsers.load && listUsers.users.length === 0 && optionSelected !== initialOptionValue) &&
                <div className={classes.errorUsers}>A organização selecionada não possui usuários cadastrados!</div>
              }

              {(optionSelected === initialOptionValue && userLogged.role === 'master') &&
                <div className={classes.errorUsers}>Selecione uma organização para exibir sua lista de usuários.</div>
              }
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    )
}

export default ListUsers
