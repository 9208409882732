import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  cardItemData: {
    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        padding: 0,
      },
    },
  },
  paper: {
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
  },
  header: {
    paddingTop: 0,
  },
  headerText: {
    fontSize: '20px',
  },
  selectUser:{
    marginTop: 5,
    width: '100%',
  },
  cardMenuItem:{
    width: '100%',
    fontSize: 17,
  },
  menuFirstLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemName: {
  },
  menuItemTypeConsults: {
    marginLeft: 5,
  },
   menuItemEmail: {
    fontSize: 14,
  },
  errorUsers: {
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#f44336',
    [theme.breakpoints.down('sm')]: {
      marginTop: 16, 
    },
  },
}))

export default styles
