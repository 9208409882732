import axios from './axiosCustom'

const HOST = process.env.REACT_APP_AUTHENTICATOR_API
const API_KEY = process.env.REACT_APP_X_API_KEY_AUTH
const ROTA = '/v2'

export async function login(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROTA}/users/login`,
    headers: {
      'x-api-key': API_KEY,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
    validateStatus: (status) => status >= 200 && status <= 302,
  })

  return res
}

export async function recoverPasswd(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROTA}/passwords/forgot`,
    headers: {
      'x-api-key': API_KEY,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })

  return res
}

export async function confirmRecoverPassword(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROTA}/passwords/forgot/confirm`,
    headers: {
      'x-api-key': API_KEY,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })

  return res
}

export async function changePasswd(data) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROTA}/passwords/change`,
    headers: {
      'x-api-key': API_KEY,
      'Content-Type': 'application/json',
    },
    data,
  })

  return res
}

export async function renewToken(sub, refreshToken){
  const formData = new FormData()
  formData.append('username', sub)
  formData.append('refresh_token', refreshToken)
  formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
  formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)

  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROTA}/users/login/refresh`,
    headers: {
      'x-api-key': API_KEY,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })

  return res
}
