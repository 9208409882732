import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { isCPF, isCNPJ, formatToCPF, formatToCNPJ } from 'brazilian-values'
import classNames from 'classnames'

import {
  Button,
  TextField,
} from '@material-ui/core'

import {
  patchClientData,
} from 'redux/dataRequested/actions'

import { MaskInput } from 'components/MaskedInput'

import styles from './styles'

const PanelClientData = () => {
  const classes = styles()
  const dispatch = useDispatch()

  const dataReq = useSelector(state => state.dataRequested.data)
  const fail = useSelector(state => state.dataRequested.fail)

  const [values, setValues] = useState({
    name: _.get(dataReq, 'name') || '',
    cpf_cnpj: _.get(dataReq, 'cpf_cnpj') || '',
    proposal: _.get(dataReq, 'proposal') || '',
  })
  const [isEditName, setIsEditName] = useState(false)
  const [isEditCpfCnpj, setIsEditCpfCnpj] = useState(false)
  const [isEditProposal, setIsEditProposal] = useState(false)

  useEffect(() => {
    setValues({
      name: _.get(dataReq, 'name') || '',
      cpf_cnpj: _.get(dataReq, 'cpf_cnpj') || '',
      proposal: _.get(dataReq, 'proposal') || '',
    })
  }, [dataReq, fail])

  const callSetIsEditField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        setIsEditName(value)
        break
      case 'cpf_cnpj':
        setIsEditCpfCnpj(value)
        break
      case 'proposal':
        setIsEditProposal(value)
        break
      default:
        break
    }
  }

  const isEditField = (fieldName) => {
    switch (fieldName) {
      case 'name':
        return isEditName
      case 'cpf_cnpj':
        return isEditCpfCnpj
      case 'proposal':
        return isEditProposal
      default:
        break
    }
  }

  const handleChange = (fieldName) => (_event, rawValue) => {
    setValues({ ...values, [fieldName]: rawValue })
  }

  const handleClickEdit = (fieldName) => {
    callSetIsEditField(fieldName, true)
  }

  const handleClickSave = (fieldName) => {
    callSetIsEditField(fieldName, false)
    dispatch(patchClientData({ [fieldName]: values[fieldName] }))
  }

  const handleClickCancel = (fieldName) => {
    callSetIsEditField(fieldName, false)
    setValues({ ...values, [fieldName]: _.get(dataReq, fieldName) || '' })
  }

  const renderButtonsEdit = (fieldName) => {
    if (_.isEmpty(dataReq)) return null

    let buttonElem = null

    if (!isEditField(fieldName)) {
      buttonElem = (
        <div>
          <Button
            variant="contained"
            size="small"
            className={classes.buttonEdit}
            onClick={() => handleClickEdit(fieldName)}
          >
            Editar
          </Button>
        </div>
      )
    } else {
      const value = _.get(values, `${fieldName}`) || ''

      buttonElem = (
        <React.Fragment>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.buttonEdit, classes.buttonColorCancel)}
            onClick={() => handleClickCancel(fieldName)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.buttonEdit, classes.buttonColorSuccess)}
            onClick={() => handleClickSave(fieldName)}
            disabled={!(fieldName === 'cpf_cnpj' ? (isCPF(value) || isCNPJ(value)) : true)}
          >
            Salvar
          </Button>
        </React.Fragment>
      )
    }

    return (
      <div className={classNames(classes.buttonContainer, 'no-print')}>
        {buttonElem}
      </div>
    )
  }

  const renderValue = (fieldName, type = 'text') => {
    const value = _.get(values, `${fieldName}`) || ''

    if (!isEditField(fieldName)) {
      let valueFormatted = value
      switch (type) {
        case 'cpf_cnpj':
          valueFormatted = isCPF(value) ? formatToCPF(value) : formatToCNPJ(value)
          break
        default:
          break
      }

      return <span className={classes.value}>{valueFormatted}</span>
    }

    if (type === 'cpf_cnpj') {
      if (!isCPF(value) && value.length >= 11) {
        return (
          <MaskInput
            name="cnpj"
            onChange={handleChange(fieldName)}
            value={value}
            errorMsg={isCNPJ(value) ? null : "Valor Inválido"}
          />
        )
      }

      return (
        <MaskInput
          name="cpf"
          onChange={handleChange(fieldName)}
          value={value}
          errorMsg={isCPF(value) ? null : "Valor Inválido"}
        />
      )
    }

    return (
      <TextField
        inputProps={{
          maxLength: 200,
        }}
        maxLength={200}
        className={classes.textField}
        onChange={e => handleChange(fieldName)(e, e.target.value)}
        value={value}
      />
    )
  }

  const renderName = () => {
    return renderValue('name')
  }

  const renderCpfCnpj = () => {
    return renderValue('cpf_cnpj', 'cpf_cnpj')
  }

  const renderProposal = () => {
    return renderValue('proposal')
  }

  return (
    <div className={classNames(classes.container)}>
      <h1 className={classes.title}>Dados do Cliente</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentTextLarge}>
          <span className={classes.description}>Nome/Razao Social:</span>
          <span className={classes.value}>{renderName()}</span>
          {renderButtonsEdit('name')}
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>CPF/CNPJ:</span>
          <span className={classes.value}>{renderCpfCnpj()}</span>
          {renderButtonsEdit('cpf_cnpj')}
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Proposta/Contrato:</span>
          <span className={classes.value}>{renderProposal()}</span>
          {renderButtonsEdit('proposal')}
        </div>
      </div>

      <hr className={classes.hl} />
    </div>
  )
}

export default PanelClientData
