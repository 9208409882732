import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import DialogConfirm from 'components/DialogConfirm'

import { getAuctionDetails } from 'services/apiMain'

import AuctionDetails from './AuctionDetails'
import styles from './styles'

const PanelVehicleAuctions = () => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)
  const accessToken = useSelector(state => state.auth.access_token)
  const tokenSelected = useSelector(state => state.dataRequested.tokenSelected)
  const type = useSelector(state => state.dataRequested.type)
  const dataReq = useSelector(state => state.dataRequested.data)
  const templateConfig = useSelector(state => state.userConfig.template)

  const [openDialogShowDetails, setOpenDialogShowDetails] = useState(false)
  const [auctionsDetailsList, setAuctionsDetailsList] = useState([])

  const internalIdAuctionSelected = useRef(null)

  if (_.get(templateConfig, 'vehicle_auction') !== true) return null

  const vehicleAuction = _.get(dataReq, 'vehicle_auction')
  const hasAuction = _.get(vehicleAuction, 'has_auction')

  const findAuctionDetails = (list, internalId) => {
    return _.find(list, { internal_id: internalId })
  }

  const hasAuctionDetails = (list, internalId) => {
    return !_.isEmpty(findAuctionDetails(list, internalId))
  }

  const changeActionViewed = (viewed) => {
    _.set(vehicleAuction, 'auction_viewed', viewed)
  }

  const toogleIsCloseAuctionDetails = (internalId) => {
    const newAuctionsDetailsList = _.cloneDeep(auctionsDetailsList)
    const auctionDetails = findAuctionDetails(newAuctionsDetailsList, internalId)
    if (_.isEmpty(auctionDetails)) return

    auctionDetails.isClose = !auctionDetails.isClose
    setAuctionsDetailsList(newAuctionsDetailsList)
  }

  const fetchAuctionDetails = async (internalId) => {
    let res = null
    try {
      res = await getAuctionDetails(
        apiKey,
        accessToken,
        type,
        tokenSelected,
        internalId,
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter leilao'
      toast.error(messageError)
      return
    }

    changeActionViewed(true)
    const content = { ...res.data, isClose: false }
    setAuctionsDetailsList([ ...auctionsDetailsList, content ])
  }

  const handleAuctionDetails = (internalId) => {
    internalIdAuctionSelected.current = internalId

    if (hasAuctionDetails(auctionsDetailsList, internalIdAuctionSelected.current)) {
      toogleIsCloseAuctionDetails(internalId)
      return
    }

    if (!_.get(vehicleAuction, 'auction_viewed')) {
      setOpenDialogShowDetails(true)
      return
    }

    fetchAuctionDetails(internalIdAuctionSelected.current)
    internalIdAuctionSelected.current = null
  }

  const handleConfirmShowDetails = () => {
    setOpenDialogShowDetails(false)
    fetchAuctionDetails(internalIdAuctionSelected.current)
    internalIdAuctionSelected.current = null
  }

  const handleCancelShowDetails = () => {
    setOpenDialogShowDetails(false)
    internalIdAuctionSelected.current = null
  }

  const renderHasAuction = () => {
    const auctionYes = hasAuction === true ? 'X' : ''
    const auctionNo = hasAuction === false ? 'X' : ''

    return (
      <div className={classes.checkItemContainer}>
        <div className={classes.checkItem}>{auctionYes}</div>
        <span>Sim</span>

        <div className={classes.checkItem}>{auctionNo}</div>
        <span>Não</span>
      </div>
    )
  }

  const renderAuctionsDetails = (internalId) => {
    const auctionDetails = findAuctionDetails(auctionsDetailsList, internalId)
    if (_.isEmpty(auctionDetails)) return null
    if (_.get(auctionDetails, 'isClose')) return null

    return <AuctionDetails auctionDetails={auctionDetails} />
  }

  const renderAuctions = () => {
    if (hasAuction !== true) return null

    const auctions = _.get(vehicleAuction, 'auctions')

    return _.map(auctions, (auction, i) => {
      const date = _.get(auction, 'date')
      const internalId = _.get(auction, 'internal_id')
      const isClose = _.get(findAuctionDetails(auctionsDetailsList, internalId), 'isClose') === false

      return (
        <React.Fragment key={i.toString()}>
          <div className={classes.containerLine}>
            <div
              className={classNames(
                classes.containerContentText,
                classes.containerContentTextLarge,
                classes.containerFlex,
              )}
            >
              <span className={classes.description}>Data do leilão:</span>
              <span className={classes.value}>
                {date}
              </span>
              <Button
                onClick={() => handleAuctionDetails(internalId)}
                color={isClose ? 'default' : 'default'}
                variant="contained"
                size="small"
                className={classes.btnAuctionDetails}
              >
                {
                  isClose
                  ? <ExpandLessIcon fontSize="small" />
                  : <strong>Detalhes</strong>
                }
              </Button>
            </div>
          </div>

          <hr className={classes.hl} />

          {renderAuctionsDetails(internalId)}
        </React.Fragment>
      )
    })
  }

  const renderDialogShowDetails = () => {
      return (
        <DialogConfirm
          open={openDialogShowDetails}
          onConfirm={handleConfirmShowDetails}
          onCancel={handleCancelShowDetails}
          title="Ver detalhes do leilão"
          content="Os detalhes do leilão tem um valor adicional ao laudo, deseja continuar?"
        />
      )
    }

  return (
    <div className={classNames(classes.container)}>
      <h1 className={classes.title}>Leilão</h1>

      <div className={classes.containerLine}>
        <div className={classNames(classes.containerContentText, classes.containerContentTextLarge)}>
          <span className={classes.description}>Veiculo de leilão:</span>
          <div className={classNames(classes.value, classes.valueCustom)}>
            {renderHasAuction()}
          </div>
        </div>
      </div>

      <hr className={classes.hl} />

      {renderAuctions()}
      {renderDialogShowDetails()}
    </div>
  )
}

export default PanelVehicleAuctions
