import axios from './axiosCustom'

const HOST = process.env.REACT_APP_FILE_MANAGER_API
const AUTHORIZATION = process.env.REACT_APP_FILE_MANAGER_AUTHORIZATION

const headers = (newHeaders = {}) => {
  const objHeaders = {
    'Content-type': 'application/json',
    'x-api-key': AUTHORIZATION,
    ...newHeaders,
  }

  return objHeaders
}

export async function fileManagerUploadImage(userId, imageFile, token) {
  const formData = new FormData()
  formData.set('data_type', 'other')
  formData.set('token', token)
  formData.set('user_id', userId)
  formData.append('file', imageFile)

  const res = await axios({
    method: 'POST',
    url: `${HOST}/upload_file`,
    headers: headers({
      'Content-type': 'multipart/form-data',
    }),
    data: formData,
  })

  return res
}

export async function fileManagerGetUrlImage(userId, fileId, token) {
  const data = {
    file_id: fileId,
    token: token,
    user_id: userId,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/get_file`,
    headers: headers(),
    data: data,
  })

  return res
}
