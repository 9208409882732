import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  container:{
  },
  title: {
    '@media print': {
      fontSize: theme.report.fontSizeTitle,
    },
  },
  subtitle: {
    '@media print': {
      fontSize: 12,
    },
  },
  containerLine: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: '5px 0',

    '@media print': {
      margin: theme.report.marginFieldInPrint,
    },

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'initial',
      },
    },
  },
  containerContentText: {
    width: '50%',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
        flexWrap: 'wrap',
      },
    },
  },
  containerContentTextSmall: {
    width: '20%',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
        flexWrap: 'wrap',
      },
    },
  },
  containerContentTextMedium: {
    width: '83%',
    maxWidth: '83%',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
        flexWrap: 'wrap',
      },
    },
  },
  containerContentLarge: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        width: 'initial',
        flexWrap: 'wrap',
      },
    },
  },
  description: {
    fontSize: theme.report.fontSize,
    fontWeight: 'bold',
    marginLeft: 15,
    marginRight: 30,
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        marginLeft: 10,
        marginRight: 10,
      },
    },

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  value: {
    fontSize: theme.report.fontSize,
    textAlign: 'justify',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        marginLeft: 10,
        marginRight: 10,
      },
    },

    '@media print': {
      fontSize: theme.report.fontSizeInPrint,
    },
  },
  valueCustom: {
    display: 'inline-block',
  },
  checkItemContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkItem: {
    border: '1px solid',
    fontSize: 20,
    lineHeight: 1,
    width: 25,
    height: 25,
    textAlign: 'center',
    display: 'inline-block',
    marginRight: 5,
    marginLeft: 20,
    verticalAlign: 'bottom',
  },
  hl: {
    border: '1px dashed #aaa',
  },
  vl: {
    borderLeft: '2px solid #aaa',
    height: 10,
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        display: 'none',
      },
    },
  },
  input: {
    height: 22,
  },
  textArea: {
    flexGrow: 1,
    fontSize: 13,
    resize: 'none',
  },
  buttonEdit: {
    backgroundColor: '#9083ff',
    color: '#fff',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#6c5bff',
    },
  },
  buttonColorSuccess: {
    backgroundColor: '#00ac00',
    '&:hover': {
      backgroundColor: '#019101',
    },
  },
  buttonColorCancel: {
    backgroundColor: '#e0e0e0',
    color: '#000',
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#d5d5d5',
    },
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default styles
