import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import styles from './styles'

const PanelVehicleEvaluation = () => {
  const classes = styles()

  const dataReq = useSelector(state => state.dataRequested.data)
  const templateConfig = useSelector(state => state.userConfig.template)

  const renderRestrictionLock = () => {
    let administrativeRestriction = _.get(dataReq, 'vehicle_evaluation.administrative_restriction')
    administrativeRestriction = _.isString(administrativeRestriction) && (administrativeRestriction !== 'NADA CONSTA')

    let judicialRestriction = _.get(dataReq, 'vehicle_evaluation.renajud_restriction') // usado o renajud_restriction para a restricao judicial
    judicialRestriction = _.isString(judicialRestriction) && (judicialRestriction !== 'NADA CONSTA')

    let tributaryRestriction = _.get(dataReq, 'vehicle_evaluation.tributary_restriction')
    tributaryRestriction = _.isString(tributaryRestriction) && (tributaryRestriction !== 'NADA CONSTA')

    const nothing = !_.isEmpty(dataReq) && ![administrativeRestriction, judicialRestriction, tributaryRestriction].includes(true)

    return (
      <div className={classes.checkItemContainer}>
        <div className={classes.checkItem}>{administrativeRestriction ? 'X' : ''}</div>
        <span>Administrativa</span>

        <div className={classes.checkItem}>{tributaryRestriction ? 'X' : ''}</div>
        <span>Tributária</span>

        <div className={classes.checkItem}>{judicialRestriction ? 'X' : ''}</div>
        <span>Judicial</span>

        <div className={classes.checkItem}>{nothing ? 'X' : ''}</div>
        <span>Nada consta</span>
      </div>
    )
  }

  const renderRenajud = () => {
    if (_.isEmpty(dataReq)) return null

    const renajud = _.get(dataReq, 'vehicle_evaluation.renajud_restriction')
    const renajudValue = renajud !== 'NADA CONSTA' ? 'Consta bloqueio judicial - RENAJUD' : renajud

    return renajudValue
  }

  const renderGravame = () => {
    if (_.isEmpty(dataReq)) return null

    const financialRestriction = _.get(dataReq, 'vehicle_evaluation.financial_restriction')
    const domainRestriction = _.get(dataReq, 'vehicle_evaluation.domain_restriction')
    const pledgeRestriction = _.get(dataReq, 'vehicle_evaluation.pledge_restriction')
    const tenantRestriction = _.get(dataReq, 'vehicle_evaluation.tenant_restriction')

    return _.some([
      financialRestriction,
      domainRestriction,
      pledgeRestriction,
      tenantRestriction,
    ], (x) => x !== 'NADA CONSTA') ? 'Sim' : 'Não'
  }

  const renderRfbRestriction = () => {
    if (_.isEmpty(dataReq)) return null

    const rfbRestriction = _.get(dataReq, 'vehicle_evaluation.rfb_restriction')

    return _.isEmpty(rfbRestriction) ? 'Não' : rfbRestriction
  }

  if (_.get(templateConfig, 'vehicle_evaluation') !== true) return null

  return (
    <div className={classNames(classes.container)}>
      <h1 className={classes.title}>Avaliação</h1>

      <div className={classes.containerLine}>
        <span className={classes.subtitle}>
          Resultado das consultas de débitos pendentes ou/e restrições
        </span>
      </div>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Restrição por roubo/furto:</span>
          <span className={classes.value}>
            {
              _.isEmpty(dataReq) ? ''
              :
              _.get(dataReq, 'vehicle_evaluation.theft_restriction') ? 'Sim' : 'Não'
            }
          </span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classNames(classes.containerContentText, classes.containerContentTextLarge)}>
          <span className={classes.description}>Bloqueio por restrição:</span>
          <div className={classNames(classes.value, classes.valueCustom)}>
            {renderRestrictionLock()}
          </div>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentTextLarge}>
          <span className={classes.description}>
            RENAJUD
            {' '}
            <span className={classes.descriptionNormal}>(Restrições judiciais sobre veículos)</span>
            :
          </span>
          <span className={classes.value}>{renderRenajud()}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Gravame ativo:</span>
          <span className={classes.value}>
            {renderGravame()}
          </span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Restrição RFB:</span>
          <span className={classes.value}>{renderRfbRestriction()}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </div>
  )
}

export default PanelVehicleEvaluation
