import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  container:{
  },
  title: {
    '@media print': {
      fontSize: theme.report.fontSizeTitle,
    },
  },
  containerImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  containerImage: {
    position: 'relative',
    margin: 10,

    '@media print': {
      margin: '0 5px',
    },
  },
  image: {
    height: 200,
    width: 200,

    '@media print': {
      height: 150,
      width: 150,
    },
  },
  buttonAddPhoto: {
    backgroundColor: '#9083ff',
    color: '#fff',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#6c5bff',
    },
  },
  containerButtonDeleteImage: {
    position: 'absolute',
    right: 0,
    margin: 2,
    opacity: 0.8,
  },
  buttonDeleteImage: {
    backgroundColor: '#ff0000',
    color: '#fff',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#cc0000',
    },
  },
}))

export default styles
